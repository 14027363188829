// Globals
@import "assets/css/_variables.scss";

// Metronic main style
@import "assets/sass/style.scss";

// Overrides

// color de icono de collapse/expand menu
path#Path-94 {
	fill: white;
}

@media (max-width: 1024px) {
	.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-wrapper {
		height: 45px !important;
	}
}

@media (max-width: 1024px) {
	.kt-aside-close {
		background-color: #1e1e2d !important;
	}

	.kt-aside-close > i {
		color: #fff !important;
	}
}

// padding global del content
.kt-content {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}

// padding global del footer
.kt-footer {
	padding-left: 1rem !important;
}

/** SIDEBAR **/
.ng-sidebar {
	max-width: 20%;
	min-width: 10%;
	padding: 1.5rem;
}

aside.right.ng-star-inserted,
.help-aside > aside {
	/*background-color: red;*/
	height: auto;
	z-index: 4500;
}

a.active.nav-link.pointer.success {
	background-color: #e2ffd9 !important;
	color: #000000 !important;
}

a.active.nav-link.pointer.danger {
	background-color: #ffd8d8 !important;
	color: #ff0000 !important;
}

a.active.nav-link.pointer.info {
	background-color: #f1f1f1 !important;
	color: #000000 !important;
}

.avoid-clicks {
	pointer-events: none !important;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
	color: #fff;
	font-weight: 400;
}

// i class del topbar (icono de ayuda, etc)
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i {
	color: inherit !important;
}

// menu activo
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text {
	color: #fff;
}

// menu hover
.kt-aside-menu .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-text {
	color: #fff;
}

// menu no activo
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
	color: #fbfbfb;
}

// child menu no activo
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-text {
	color: #fff;
}

// child menu activo
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text {
	color: #fff;
}

// child menu hover
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-text {
	color: #fff;
}

// menu icono no activo
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
	color: #fff;
}

// menu icono activo
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon {
	color: #fff;
}

// menu icono hover
.kt-aside-menu .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon {
	color: #fff;
}


// div superior toolbar
@media (min-width: 1025px) {
	.kt-header {
		background-color: #fff;
		border-bottom: 1px solid #eff0f6;
	}
}

body {
	overflow-x: hidden;
}

@media (min-width:1025px) {
	.kt-header {
		background-color: #fff;
		border-bottom: 1px solid #eff0f6
	}

	.kt-header--fixed:not(.kt-subheader--fixed) .kt-header {
		border-bottom: 0;
		-webkit-box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
		box-shadow: 0 0 40px 0 rgba(82,63,105,.1)
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
		border-radius: 4px
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
		color: #6c7293;
		font-weight: 500
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow {
		color: #c8cde8
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-icon {
		color: #c8cde8
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__link-text {
		color: #fff
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__hor-arrow, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__hor-arrow, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__hor-arrow {
		color: #fff
	}

	.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__hor-icon, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__hor-icon, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__hor-icon {
		color: #fff
	}
}

.kt-aside-secondary--enabled .kt-header .kt-header__topbar {
	padding-right: 0
}

.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i {
	-webkit-transition: all .3s;
	transition: all .3s;
	color: #c8cad5
}

.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon svg g [fill] {
	fill: #fff !important;
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-welcome {
	-webkit-transition: all .3s;
	transition: all .3s;
	color: #959cb6
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username {
	-webkit-transition: all .3s;
	transition: all .3s;
	color: #6c7293
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-badge.kt-badge--username {
	height: 35px;
	width: 35px;
	font-size: 1.4rem;
	font-weight: 500
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i {
	color: #c8cad5
}

.kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon, .kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon {
	-webkit-transition: all .3s;
	transition: all .3s;
	background-color: rgba(77,89,149,.06)
}

.kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon i, .kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon i {
	-webkit-transition: all .3s;
	transition: all .3s;
	color: #fff;
}

.kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--user .kt-header__topbar-user, .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--user .kt-header__topbar-user {
	-webkit-transition: all .3s;
	transition: all .3s;
	background-color: rgba(77,89,149,.06)
}

.kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel, .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel {
	-webkit-transition: all .3s;
	transition: all .3s;
	background-color: #fff;
}

.kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel .kt-header__topbar-icon, .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel .kt-header__topbar-icon {
	-webkit-transition: all .3s;
	transition: all .3s;
	background-color: transparent !important
}

.kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i, .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i {
	-webkit-transition: all .3s;
	transition: all .3s;
	color: #fff
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--search .kt-input-icon .kt-input-icon__icon i {
	color: #74788d
}

@media (max-width:1024px) {
	.kt-header-mobile {
		-webkit-box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
		box-shadow: 0 0 40px 0 rgba(82,63,105,.1)
	}

	.kt-header__topbar {
		background-color: #fff;
		-webkit-box-shadow: none;
		box-shadow: none
	}

	.kt-header__topbar--mobile-on .kt-header-mobile {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-bottom-color: transparent
	}

	.kt-header__topbar--mobile-on .kt-header__topbar {
		-webkit-box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
		box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
		border-top: 1px solid #ebedf2
	}
}

@media (min-width: 1025px) {
	.kt-invoice-2 .kt-invoice__container {
		width: 90%;
		margin: 0 auto;
	}
}

.kt-invoice__title {
	font-size: 1.2rem;
	text-transform: capitalize;
	font-weight: 600;
	color: #74788d;
}

.kt-subheader--solid .kt-subheader {
	margin: 0 0 -5px 0 !important;
}

.kt-invoice__head__bg {
	/* Fallback */
	background-image: url('/assets/media/bg/invoice.png');
	/* CSS gradients */
	background-image: url('/assets/media/bg/invoice.png'), -moz-linear-gradient(top, #ffffff00 0%, #ffffff 100%);
	background-image: url('/assets/media/bg/invoice.png'), -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff00), color-stop(100%, #ffffff));
	background-image: url('/assets/media/bg/invoice.png'), linear-gradient(to bottom, #ffffff00, #ffffff);
}

.kt-quick-panel {
	width: 500px !important;
}

.kt-quick-panel--right .kt-quick-panel {
	right: -550px;
	left: auto;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__title {
	font-size: 2.2rem;
	font-weight: 700;
}

// retoques dark theme
.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__subtitle {
	font-size: 1.2rem;
	text-transform: initial;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__subtitle__timestamp {
	font-size: 1.0rem;
	text-transform: initial;
	font-weight: 400;
}

.kt-invoice-2 .kt-invoice__body {
	padding: 2rem 0;
}

.kt-invoice-2 .kt-invoice__head {
	padding: 60px 0;
}

.kt-header, .kt-header-mobile {
	background: $sigef-topbar !important;
}

.kt-menu__link__item {
	color: #f2f3f8;
}

a.kt-menu__link:hover {
	Background: #ffffff1f !important;
}

.kt-menu__link__item:hover {
	color: #fff;
}

@media (min-width: 1025px) {
	.kt-menu__link--header--active a.kt-menu__link {
		background: #fff !important;
	}

	.kt-menu__link--header--active a.kt-menu__link > span.kt-menu__link__item {
		color: #464646f0 !important;
	}
}

// fin retoques dark theme

// foco de los botones de kendo
.kt-radio:focus, label.kt-checkbox:focus, .kt-checkbox-list:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
	outline: none;
}

*:focus {
	outline: none !important;
}

.font-weight-bolder {
	font-weight: 500 !important;
}

.btn.btn-light-success {
	color: #1bc5bd;
	background-color: #c9f7f5;
	border-color: transparent;
}

.btn.btn-light-success.active, .btn.btn-light-success.focus:not(.btn-text), .btn.btn-light-success:focus:not(.btn-text), .btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) {
	color: #fff;
	background-color: #1bc5bd;
	border-color: transparent;
}


.kt-aside {
	background: $sigef-menu !important;
}

kt-brand {
	background: $sigef-menu-header !important;
}

.nav-tabs.nav-tabs-line.nav-tabs-line-sigef.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-sigef.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-sigef a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-sigef a.nav-link.active {
	color: $sigef-tab-option;
	border-bottom: 1px solid $sigef-tab-line;
}

.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line a.nav-link:hover > i, .nav-tabs.nav-tabs-line a.nav-link.active > i {
	color: $sigef-tab-option !important;
}

a.sigef.nav-link {
	color: #ffffff !important;
	background-color: $sigef-button !important;
}

.kt-menu__submenu {
	background: $sigef-menu-submenu;
}

.kt-menu__item--submenu.kt-menu__item--open {
	background: $sigef-menu-open;
}

i.kt-menu__ver-arrow.la.la-angle-right {
	color: #FFF !important;
}

.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
	color: $sigef-tab-option !important;
	font-weight: 600;
}

.k-button:not(.btn-mixero):hover, .k-button.k-state-hover {
	background-color: $sigef-menu-submenu !important;
}

.form-control:focus, .form-control:active {
	box-shadow: $sigef-menu-active !important;
}

.kt-aside-menu .kt-menu__nav {
	padding: 0 !important;
}

.kt-loading-bar .progress-bar {
	background-color: $sigef-menu !important;
}

.kt-scrolltop {
	background: $sigef-topbar !important;
}

.kt-splash-screen .mat-progress-spinner circle, .kt-splash-screen .mat-spinner circle {
	stroke: $sigef-topbar !important;
}

.kt-splash-screen img {
	margin-bottom: 45px;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link {
	padding-left: 45px !important;
}

// background del child activo
li.kt-menu__item.kt-menu__item--active.kt-menu__item--here {
	background: $sigef-menu-active !important;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
	padding: 9px 15px !important;
}

.kt-link:hover:after {
	border-bottom: 1px solid $sigef-verde-accent !important;
	opacity: 0.7 !important;
}

.kt-link {
	color: $sigef-verde-accent !important;
}

.kt-quick-panel__nav > ul > li.nav-item > a.nav-link {
	font-weight: 600 !important;
}

html, body {
	font-weight: initial !important;
	//background-image: url('/assets/media/bg/boxed-bg.jpg');
}

.o-40 {
	opacity: 0.4 !important;
	pointer-events: none !important;
}
