/*@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "material-design-icons/iconfont/material-icons.css";*/



/* Importing Bootstrap SCSS file. */
/*@import 'bootstrap/scss/bootstrap';*/

/* Importing Bootstrap SCSS file. */
/*@import 'bootstrap/scss/bootstrap';*/

// Globals
@import "assets/css/_variables.scss";

// Vendors Bundle
@import "assets/vendors/global/vendors.bundle.css";

// Kendo
@import "assets/css/kendo/_kendo.scss";

// Metronic
@import "assets/css/metronic/_metronic.scss";

// Invoice "2" CSS
@import "assets/css/sigef/pages/invoices/invoice-2.css";

// SIGEF
@import "assets/css/sigef/_sigef.scss";

// JKanban
@import "assets/css/jkanban/jkanban.css"; // original del proyecto
@import "assets/css/jkanban/jkanban_custom.css"; // overrides de sigef

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
	min-width: 600px !important;
	width: 100% !important;
}

.position-static {
	position: static;
}

// no-print media
@media print {
	.no-print, .no-print * {
		display: none !important;
	}
}

.pointer {
	cursor: pointer;
}

.z-index-15 {
	z-index: 15000 !important;
}
