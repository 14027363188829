.card {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #ffffff;
	background-clip: border-box;
	border: 1px solid #EBEDF3;
	border-radius: 0.42rem;
}

	.card > hr {
		margin-right: 0;
		margin-left: 0;
	}

	.card > .list-group {
		border-top: inherit;
		border-bottom: inherit;
	}

		.card > .list-group:first-child {
			border-top-width: 0;
			border-top-left-radius: calc(0.42rem - 1px);
			border-top-right-radius: calc(0.42rem - 1px);
		}

		.card > .list-group:last-child {
			border-bottom-width: 0;
			border-bottom-right-radius: calc(0.42rem - 1px);
			border-bottom-left-radius: calc(0.42rem - 1px);
		}

.card-body {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 1px;
	padding: 2.25rem;
}

.card-title {
	margin-bottom: 2rem;
	font-weight: 700 !important;
	font-size: 1rem !important;
	text-transform: uppercase !important;
}

.card-subtitle {
	margin-top: -1rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link + .card-link {
	margin-left: 2.25rem;
}

.card-header {
	padding: 2rem 2.25rem;
	margin-bottom: 0;
	background-color: #ffffff;
	border-bottom: 1px solid #EBEDF3;
}

	.card-header:first-child {
		border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
	}

	.card-header + .list-group .list-group-item:first-child {
		border-top: 0;
	}

.card-footer {
	border-top: 1px solid #EBEDF3;
}

	.card-footer:last-child {
		border-radius: 0 0 calc(0.42rem - 1px) calc(0.42rem - 1px);
	}

.card-header-tabs {
	margin-right: -1.125rem;
	margin-bottom: -2rem;
	margin-left: -1.125rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -1.125rem;
	margin-left: -1.125rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 100%;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.42rem - 1px);
	border-top-right-radius: calc(0.42rem - 1px);
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.42rem - 1px);
	border-bottom-left-radius: calc(0.42rem - 1px);
}

.card-deck .card {
	margin-bottom: 12.5px;
}

@media (min-width: 576px) {
	.card-deck {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -12.5px;
		margin-left: -12.5px;
	}

		.card-deck .card {
			-webkit-box-flex: 1;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-right: 12.5px;
			margin-bottom: 0;
			margin-left: 12.5px;
		}
}

.card-group > .card {
	margin-bottom: 12.5px;
}

@media (min-width: 576px) {
	.card-group {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
	}

		.card-group > .card {
			-webkit-box-flex: 1;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-bottom: 0;
		}

			.card-group > .card + .card {
				margin-left: 0;
				border-left: 0;
			}

			.card-group > .card:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

				.card-group > .card:not(:last-child) .card-img-top,
				.card-group > .card:not(:last-child) .card-header {
					border-top-right-radius: 0;
				}

				.card-group > .card:not(:last-child) .card-img-bottom,
				.card-group > .card:not(:last-child) .card-footer {
					border-bottom-right-radius: 0;
				}

			.card-group > .card:not(:first-child) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

				.card-group > .card:not(:first-child) .card-img-top,
				.card-group > .card:not(:first-child) .card-header {
					border-top-left-radius: 0;
				}

				.card-group > .card:not(:first-child) .card-img-bottom,
				.card-group > .card:not(:first-child) .card-footer {
					border-bottom-left-radius: 0;
				}
}

.card-columns .card {
	margin-bottom: 2rem;
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}

		.card-columns .card {
			display: inline-block;
			width: 100%;
		}
}

.accordion > .card {
	overflow: hidden;
}

	.accordion > .card:not(:last-of-type) {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.accordion > .card:not(:first-of-type) {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.accordion > .card > .card-header {
		border-radius: 0;
		margin-bottom: -1px;
	}


.bg-diagonal {
	position: relative;
	background-color: #ffffff;
	overflow: hidden;
}

	.bg-diagonal .card-body {
		position: relative;
		z-index: 2;
	}

	.bg-diagonal:before {
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #F3F6F9;
	}

	.bg-diagonal:after {
		content: " ";
		width: 200%;
		height: 1000px;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 45%;
		-webkit-transform: rotate(20deg);
		transform: rotate(20deg);
		background-color: #ffffff;
	}


.card.card-custom {
	-webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
	box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
	border: 0;
}

	.card.card-custom > .card-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		min-height: 45px;
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent;
	}

		.card.card-custom > .card-header .card-title {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			margin: 0.5rem;
			margin-left: 0;
		}

			.card.card-custom > .card-header .card-title .card-icon {
				margin-right: 0.75rem;
				line-height: 0;
			}

				.card.card-custom > .card-header .card-title .card-icon i {
					font-size: 1.25rem;
					color: #7E8299;
					line-height: 0;
				}

					.card.card-custom > .card-header .card-title .card-icon i:after, .card.card-custom > .card-header .card-title .card-icon i:before {
						line-height: 0;
					}

				.card.card-custom > .card-header .card-title .card-icon .svg-icon svg {
					height: 24px;
					width: 24px;
				}

					.card.card-custom > .card-header .card-title .card-icon .svg-icon svg g [fill] {
						-webkit-transition: fill 0.3s ease;
						transition: fill 0.3s ease;
						fill: #7E8299;
					}

					.card.card-custom > .card-header .card-title .card-icon .svg-icon svg:hover g [fill] {
						-webkit-transition: fill 0.3s ease;
						transition: fill 0.3s ease;
					}

			.card.card-custom > .card-header .card-title,
			.card.card-custom > .card-header .card-title .card-label {
				font-weight: 500;
				font-size: 1.275rem;
				color: #181C32;
			}

				.card.card-custom > .card-header .card-title .card-label {
					margin: 0 0.75rem 0 0;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
				}

				.card.card-custom > .card-header .card-title small {
					color: #B5B5C3;
					font-size: 1rem;
				}

		.card.card-custom > .card-header .card-toolbar {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			margin: 0.5rem 0;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		}

		.card.card-custom > .card-header.card-header-tabs-line {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			-webkit-box-align: stretch;
			-ms-flex-align: stretch;
			align-items: stretch;
		}

			.card.card-custom > .card-header.card-header-tabs-line .card-toolbar {
				margin: 0;
			}

			.card.card-custom > .card-header.card-header-tabs-line .nav {
				border-bottom-color: transparent;
			}

				.card.card-custom > .card-header.card-header-tabs-line .nav .nav-item {
					-webkit-box-align: stretch;
					-ms-flex-align: stretch;
					align-items: stretch;
				}

				.card.card-custom > .card-header.card-header-tabs-line .nav .nav-link {
					padding-top: 2rem;
					padding-bottom: 2rem;
				}

		.card.card-custom > .card-header.card-header-right {
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
		}

	.card.card-custom > .card-body {
		padding: 2rem 2.25rem;
	}

	.card.card-custom > .card-footer {
		background-color: transparent;
	}

	.card.card-custom.card-stretch {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100%;
	}

		.card.card-custom.card-stretch.gutter-b {
			height: calc(100% - 25px);
		}

		.card.card-custom.card-stretch.card-stretch-half {
			height: 50%;
		}

			.card.card-custom.card-stretch.card-stretch-half.gutter-b {
				height: calc(50% - 25px);
			}

		.card.card-custom.card-stretch.card-stretch-third {
			height: 33.33%;
		}

			.card.card-custom.card-stretch.card-stretch-third.gutter-b {
				height: calc(33.33% - 25px);
			}

		.card.card-custom.card-stretch.card-stretch-fourth {
			height: 25%;
		}

			.card.card-custom.card-stretch.card-stretch-fourth.gutter-b {
				height: calc(25% - 25px);
			}

	.card.card-custom.card-fit > .card-header {
		border-bottom: 0;
	}

	.card.card-custom.card-fit > .card-footer {
		border-top: 0;
	}

	.card.card-custom.card-space {
		padding-left: 2.25rem;
		padding-right: 2.25rem;
	}

		.card.card-custom.card-space > .card-header {
			padding-left: 0;
			padding-right: 0;
		}

		.card.card-custom.card-space > form > .card-body,
		.card.card-custom.card-space > .card-body {
			padding-left: 0;
			padding-right: 0;
		}

		.card.card-custom.card-space > form > .card-footer,
		.card.card-custom.card-space > .card-footer {
			padding-left: 0;
			padding-right: 0;
		}

	.card.card-custom.card-sticky > .card-header {
		-webkit-transition: left 0.3s, right 0.3s, height 0.3s;
		transition: left 0.3s, right 0.3s, height 0.3s;
		min-height: 70px;
	}

.card-sticky-on .card.card-custom.card-sticky > .card-header {
	-webkit-transition: left 0.3s, right 0.3s, height 0.3s;
	transition: left 0.3s, right 0.3s, height 0.3s;
	position: fixed;
	-webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
	box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
	z-index: 101;
	background: #ffffff;
}

.card.card-custom.card-transparent {
	background-color: transparent;
}

.card.card-custom.card-shadowless {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.card.card-custom.card-border {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #EBEDF3;
}

.card.card-custom .card-scroll {
	position: relative;
	overflow: auto;
}

.card.card-custom.card-collapsed > form,
.card.card-custom.card-collapsed > .card-body {
	display: none;
}

.card.card-custom .card-header .card-toolbar [data-card-tool="toggle"] i {
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
}

.card.card-custom.card-collapsed .card-header .card-toolbar [data-card-tool="toggle"] i, .card.card-custom.card-collapse .card-header .card-toolbar [data-card-tool="toggle"] i {
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.card-spacer {
	padding: 2rem 2.25rem !important;
}

.card-spacer-x {
	padding-left: 2.25rem !important;
	padding-right: 2.25rem !important;
}

.card-spacer-y {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}

.card-rounded {
	border-radius: 0.42rem;
}

.card-rounded-top {
	border-top-left-radius: 0.42rem;
	border-top-right-radius: 0.42rem;
}

.card-rounded-bottom {
	border-bottom-left-radius: 0.42rem;
	border-bottom-right-radius: 0.42rem;
}

@media (max-width: 767.98px) {
	.card.card-custom > .card-header:not(.flex-nowrap) {
		min-height: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

.rounded-card {
	border-radius: 0.42rem;
}

.rounded-card-top {
	border-top-left-radius: 0.42rem;
	border-top-right-radius: 0.42rem;
}

.rounded-card-bottom {
	border-bottom-left-radius: 0.42rem;
	border-bottom-right-radius: 0.42rem;
}


.accordion .card {
	overflow: visible !important;
}

	.accordion .card:last-child {
		margin-bottom: 0;
	}

	.accordion .card .card-header {
		cursor: pointer;
		margin: 0;
		border-bottom: 0;
		padding: 0;
		background-color: #F3F6F9;
	}

		.accordion .card .card-header .card-title {
			padding: 1rem 1.25rem;
			margin: 0;
			font-size: 1.15rem;
			font-weight: 500;
			color: #3699FF;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-transition: all 0.15s ease;
			transition: all 0.15s ease;
		}

			.accordion .card .card-header .card-title > i {
				margin-right: 1rem;
				font-size: 1.4rem;
				color: #3699FF;
				-webkit-transition: all 0.15s ease;
				transition: all 0.15s ease;
			}

			.accordion .card .card-header .card-title .card-label {
				-webkit-box-flex: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
			}

			.accordion .card .card-header .card-title.collapsed {
				color: #3F4254;
				-webkit-transition: all 0.15s ease;
				transition: all 0.15s ease;
			}

				.accordion .card .card-header .card-title.collapsed > i {
					color: #B5B5C3;
					-webkit-transition: all 0.15s ease;
					transition: all 0.15s ease;
				}

			.accordion .card .card-header .card-title:after {
				right: 1rem;
			}

		.accordion .card .card-header .card-text {
			-webkit-box-flex: 1;
			-ms-flex-positive: 1;
			flex-grow: 1;
		}

	.accordion .card .card-body {
		padding: 1rem 1.25rem;
		font-size: 1rem;
	}

.accordion:last-child {
	margin-bottom: 0;
}

.accordion-outline .card {
	background-color: transparent;
}

.accordion.accordion-light .card {
	border: 0;
	border-top: 1px solid #EBEDF3;
}

	.accordion.accordion-light .card .card-header {
		background-color: transparent;
		border: 0;
	}

		.accordion.accordion-light .card .card-header .card-title {
			padding: 1rem 0;
		}

			.accordion.accordion-light .card .card-header .card-title:after {
				right: 0;
			}

	.accordion.accordion-light .card .card-body {
		padding: 0 0 1rem 0;
	}

	.accordion.accordion-light .card:first-child {
		border-top: 0;
	}

		.accordion.accordion-light .card:first-child .card-header {
			margin: 0;
		}

	.accordion.accordion-light .card:last-child .card-body {
		padding: 0;
		margin: 0;
	}

.accordion.accordion-light-borderless .card {
	border-top: 0;
}

.accordion.accordion-solid .card {
	border: 0;
	margin-bottom: 1.25rem;
}

	.accordion.accordion-solid .card .card-header {
		padding: 0;
		background-color: transparent;
	}

		.accordion.accordion-solid .card .card-header .card-title {
			background-color: #F3F6F9;
			border-radius: 0.42rem;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

			.accordion.accordion-solid .card .card-header .card-title.collapsed {
				border-radius: 0.42rem;
			}

	.accordion.accordion-solid .card .card-body {
		border: 1px solid #F3F6F9;
		border-bottom-left-radius: 0.42rem;
		border-bottom-right-radius: 0.42rem;
	}

	.accordion.accordion-solid .card:last-child {
		margin: 0;
	}

.accordion.accordion-toggle-plus .card .card-header .card-title {
	color: #3699FF;
	position: relative;
}

	.accordion.accordion-toggle-plus .card .card-header .card-title:after {
		-webkit-transition: all 0.15s ease;
		transition: all 0.15s ease;
		position: absolute;
		font-family: Ki;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		line-height: 1;
		text-decoration: inherit;
		text-rendering: optimizeLegibility;
		text-transform: none;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		content: "";
		font-size: 0.75rem;
		color: #3699FF;
	}

	.accordion.accordion-toggle-plus .card .card-header .card-title.collapsed {
		color: #3F4254;
	}

		.accordion.accordion-toggle-plus .card .card-header .card-title.collapsed:after {
			-webkit-transition: all 0.15s ease;
			transition: all 0.15s ease;
			color: #7E8299;
			font-family: Ki;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			line-height: 1;
			text-decoration: inherit;
			text-rendering: optimizeLegibility;
			text-transform: none;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-smoothing: antialiased;
			content: "";
		}

.accordion.accordion-toggle-arrow .card .card-header .card-title {
	color: #3699FF;
	position: relative;
}

	.accordion.accordion-toggle-arrow .card .card-header .card-title:after {
		position: absolute;
		font-family: Ki;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		line-height: 1;
		text-decoration: inherit;
		text-rendering: optimizeLegibility;
		text-transform: none;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		content: "";
		color: #3699FF;
		font-size: 0.7rem;
		-webkit-transition: all 0.15s ease;
		transition: all 0.15s ease;
	}

	.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed {
		color: #3F4254;
		-webkit-transition: all 0.15s ease;
		transition: all 0.15s ease;
	}

		.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed:after {
			color: #7E8299;
			-webkit-transition: all 0.15s ease;
			transition: all 0.15s ease;
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
			/*rtl:begin:*/
			/*rtl:end:*/
		}

.accordion.accordion-svg-toggle .card .card-header .card-title .svg-icon svg {
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	/*rtl:begin:*/
	/*rtl:end:*/
}

.accordion.accordion-svg-toggle .card .card-header .card-title.collapsed .svg-icon svg {
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
	-webkit-transform: rotate(0);
	transform: rotate(0);
}

.accordion.accordion-panel .card {
	border-radius: 0.42rem;
}

	.accordion.accordion-panel .card .card-header .card-title {
		background-color: #fff;
		border-radius: 0.42rem;
	}

	.accordion.accordion-panel .card .card-body {
		padding-top: 0;
		border: 0;
		border-radius: 0.42rem;
	}


.card-footer:last-child {
	border-radius: 0 0 0.35rem 0.35rem;
}

.card-footer {
	background-color: initial !important;
}
