
.kanban-board {
	width: 250px;
	margin-left: 0px;
	margin-right: 15px;
	border-radius: 6px;
	background-color: #e7e5e5;
}

.kanban-board header {
	font-size: 13px;
	padding: 5px;
	font-weight: 500;
}

.kanban-board header .kanban-title-board {
	font-weight: 500;
}

header.kanban-board-header {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.kanban-board .kanban-drag {
	min-height: 165px;
	padding: 5px;
	background-color: #e7e5e5;
	margin-top: 10px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.kanban-item {
	padding: 0px !important;
	background-color: inherit;
	min-height: 100px; /* Esto es para el dropzone cuando no tiene items */
}

.kanban-item-encierre {
	border: 4px dashed #e7e5e5;
	padding: 1rem !important;
}

/*.kanban-item-encierre:after {
	font-family: "Font Awesome 5 Free";
	content: "\f245";
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
	font-weight: 900;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	font-size: 4rem;
}*/

.kanban-item-encierre:before {
	content: "Arrastre y suelte aquí las tropas de otros corrales";
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 1rem;
}

.kanban-sub-title-board {
	font-size: 11px;
	font-style: oblique;
	font-weight: 400;
}
