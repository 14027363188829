$danger: #FD397A;
$sigef-red: #FB2D2A;
$sigef-yellow: #FBDF2A;
$sigef-green: #97BD57;
$sigef-grey: #CDD2C6;
$sigef-darkgrey: #74788d;

// temporales

$sigef-verde-accent: #006837;
$sigef-primary: #009245;

$sigef-topbar: #00592F;
$sigef-menu-header: $sigef-verde-accent;
$sigef-menu-open: #004E28;
$sigef-menu-active: $sigef-primary;
$sigef-menu: $sigef-verde-accent;
$sigef-menu-submenu: #00421F;

$sigef-tab-option: $sigef-verde-accent;
$sigef-tab-line: $sigef-verde-accent;

$sigef-button: $sigef-primary;

$sigef-label: #828282;

$sigef-button-alpha: #009245A6; 
