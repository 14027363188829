
// Globals
@import "assets/css/_variables.scss";

// Configuración base de Kendo Theme Builder for Angular
$base-theme: Bootstrap;
$skin-name: SIGEF;
$swatch-name: Bootstrap;
$border-radius: 0.25rem;
$accent: $sigef-tab-option;
$secondary: #e4e7eb;
$info: #17a2b8;
$success: #28a745;
$warning: #ffc107;
$error: #dc3545;
$body-bg: #ffffff;
$body-color: #292b2c;
$component-bg: #ffffff;
$component-color: #292b2c;
$card-cap-bg: #f7f7f9;
$card-cap-color: #292b2c;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;

// Tipografía usada por Metronic
$font-family-sans-serif: Poppins,Helvetica, sans-serif;
$font-family-base: Poppins,Helvetica, sans-serif;

$kt-link-color: $sigef-tab-option;

// Kendo SASS
@import "@progress/kendo-theme-bootstrap/scss/core/functions/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/_variables.scss";
@import "@progress/kendo-theme-bootstrap/scss/core/mixins/_index.scss";


// Dependencies
@import "@progress/kendo-theme-bootstrap/scss/common/_index.scss";


// Generic content
@import "@progress/kendo-theme-bootstrap/scss/styling/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/avatar/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/badge/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/chip/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/cursor/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/icons/_index.scss";
/*@import "@progress/kendo-theme-bootstrap/scss/list/_index.scss";*/
@import "@progress/kendo-theme-bootstrap/scss/forms/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/listgroup/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/adaptive/_index.scss";
/*@import "@progress/kendo-theme-bootstrap/scss/loading/_index.scss";*/
@import "@progress/kendo-theme-bootstrap/scss/progressbar/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/ripple/_index.scss";
/*@import "@progress/kendo-theme-bootstrap/scss/table/_index.scss";*/
/*@import "@progress/kendo-theme-bootstrap/scss/typography/_index.scss";*/
@import "@progress/kendo-theme-bootstrap/scss/messagebox/_index.scss";


// Basic forms
@import "@progress/kendo-theme-bootstrap/scss/button/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/action-buttons/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/input/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/checkbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/radio/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/validator/_index.scss";


// Inputs
@import "@progress/kendo-theme-bootstrap/scss/autocomplete/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/colorpicker/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/combobox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/datetimepicker/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/dropdowngrid/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/dropdownlist/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/dropdowntree/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/maskedtextbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/multiselect/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/numerictextbox/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/upload/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/dropzone/_index.scss";


// Other forms
//@import "@progress/kendo-theme-bootstrap/scss/listbox/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/slider/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/switch/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/rating/_index.scss";


// Date time
@import "@progress/kendo-theme-bootstrap/scss/calendar/_index.scss";


// Command interfaces
@import "@progress/kendo-theme-bootstrap/scss/menu/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/toolbar/_index.scss";


// Popups and modals
@import "@progress/kendo-theme-bootstrap/scss/dialog/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/drawer/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/notification/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/popup/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/responsivepanel/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/tooltip/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/window/_index.scss";


// Navigation
//@import "@progress/kendo-theme-bootstrap/scss/breadcrumb/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/pager/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/panelbar/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/stepper/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/tabstrip/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/treeview/_index.scss";


// Layout & containers
//@import "@progress/kendo-theme-bootstrap/scss/layout/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/card/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/splitter/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/tilelayout/_index.scss";


// Data management
@import "@progress/kendo-theme-bootstrap/scss/grid/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/listview/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/spreadsheet/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/pivotgrid/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/treelist/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/filter/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/filemanager/_index.scss";


// Editor
//import "@progress/kendo-theme-bootstrap/scss/editor/_index.scss";


// Scheduling
//@import "@progress/kendo-theme-bootstrap/scss/gantt/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/scheduler/_index.scss";


// Misc
//@import "@progress/kendo-theme-bootstrap/scss/chat/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/mediaplayer/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/timeline/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/pdf-viewer/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/scroller/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/scrollview/_index.scss";


// Dataviz
@import "@progress/kendo-theme-bootstrap/scss/dataviz/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/map/_index.scss";


// Overrides

.k-dialog-button-layout-stretched .k-button {
	border-radius: 4px !important;
	padding: 0.375rem 1.75rem !important;
	margin-right: 3px !important;
	/*width: auto !important;*/
	flex: inherit !important;
}

.k-dialog-button-layout-stretched {
	/*max-width: 35% !important;*/
	padding: 1rem !important;
	border: 0 !important;
}

/* Padding del header de kendoGrid (para simular que el scrollbar es una columna) */
div.k-grid-header, div.k-grid-footer {
	padding: 0 !important;
}

/* Quita el borde derecho, al no haber scrollbar no es necesario y queda como 'doble'*/
div.k-grid-header-wrap {
	border-right: 0 !important;
}

/* quitar scrollbar de kendgroGrid si no hay overflow */
div.k-grid-content.k-virtual-content {
	overflow: auto !important;
}

.k-widget.k-window.k-dialog {
	max-height: 80vh;
}

.k-window-content, .k-prompt-container {
	overflow-x: hidden !important;
	color: #646c9a;
}

// columna de agrupado en kgrid
:host ::ng-deep .k-grid .k-group-col {
	padding: 0;
	width: 15px !important;
}

// signo + en kgrid
:host ::ng-deep .k-grid .k-hierarchy-col {
	padding: 0;
	width: 15px !important;
	color: black !important;
}

// bold en los header de kgrid
th.k-header {
	font-weight: 400;
}

// row inactiva para kgrid
:host ::ng-deep .k-grid tr.inactiva {
	background-color: #ffe7e7d4 !important;
}

.k-grid a {
	color: $kt-link-color !important;
	text-decoration: none;
}

.k-dateinput-wrap {
	font-weight: 400;
}

.k-state-invalid > .k-numeric-wrap, .ng-invalid.ng-touched > .k-numeric-wrap, .ng-invalid.ng-dirty > .k-numeric-wrap {
	color: inherit;
	outline: none;
	border: none;
}

// foco de los botones de kendo
.k-button:focus, .k-button.k-state-focused, .k-picker-wrap:focus, .k-dropdown-wrap:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-dialog-wrapper {
	z-index: 100000;
}

.k-chart-tooltip-wrapper {
	z-index: 100002;
}

.k-radio:checked {
	border-color: $sigef-menu-active !important;
	color: #fff;
	background-color: $sigef-menu-active !important;
}

.k-radio:focus {
	border-color: $sigef-menu-active !important;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-button:hover, .k-button.k-state-hover {
	border-color: $sigef-menu !important;
	color: #fff !important;
	//background-color: $sigef-menu !important;
}

.k-numerictextbox .k-numeric-wrap.k-state-focused {
	border-color: $sigef-menu-active !important;
	color: #000 !important;
	background-color: #ffffff;
	/*box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;*/
}

.k-textbox:focus, .k-textbox.k-state-focus, .k-textbox.k-state-focused, .k-textarea:focus, .k-textarea.k-state-focus, .k-textarea.k-state-focused {
	border-color: $sigef-menu-active !important;
	color: #000 !important;
	background-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-button:focus, .k-button.k-state-focused, .k-picker-wrap:focus, .k-dropdown-wrap:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-datepicker .k-picker-wrap.k-state-focused, .k-datepicker .k-picker-wrap.k-state-active, .k-timepicker .k-picker-wrap.k-state-focused, .k-timepicker .k-picker-wrap.k-state-active, .k-datetimepicker .k-picker-wrap.k-state-focused, .k-datetimepicker .k-picker-wrap.k-state-active {
	border-color: $sigef-menu-active !important;
	color: #292b2c !important;
	background-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-combobox > .k-state-focused, .k-combobox .k-state-active {
	border-color: $sigef-menu-active !important;
	color: #292b2c !important;
	background-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-dateinput .k-state-focused {
	border-color: $sigef-menu-active !important;
	color: #292b2c !important;
	background-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-multiselect {
	height: 2.4rem !important;
}

.k-multiselect.k-state-focused > .k-multiselect-wrap, .k-state-focused.k-dropdowntree > .k-multiselect-wrap {
	border-color: $sigef-menu-active !important;
	color: #292b2c !important;
	background-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-button:focus, .k-button.k-state-focused, .k-picker-wrap:focus, .k-dropdown-wrap:focus {
	border-color: $sigef-menu-active !important;
	box-shadow: 0 0 0 0.2rem rgba(0, 104, 55, 0.5) !important;
}

.k-grid a {
	color: $sigef-tab-option !important;
}

.k-window-content, .k-prompt-container, .k-window-title {
	color: $sigef-label !important;
}

.k-grid-header .k-header > .k-link {
	margin: -.75rem;
	padding: .75rem;
	line-height: inherit;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	outline: 0;
}

.k-link, .k-link:hover {
	color: inherit;
	text-decoration: none;
	outline: 0;
	cursor: pointer;
}

.k-dropdown {
	width: 12.4em;
	border-width: 0;
	outline: 0;
	background: 0 0;
	box-sizing: border-box;
	font-family: Poppins,Helvetica,sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	text-align: left;
	white-space: nowrap;
	display: inline-flex;
	vertical-align: middle;
	position: relative;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.k-dropdown .k-dropdown-wrap {
	border-radius: .25rem;
	padding: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	position: relative;
	transition: all .1s ease;
	cursor: pointer;
	outline: 0;
	transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
	color: #212529;
	background-color: #e4e7eb;
	border: 1px solid #e4e7eb;
}

.k-dropdown .k-dropdown-wrap .k-input-inner {
	padding: .375rem .75rem;
	width: 100%;
	min-width: 0;
	height: calc(.75rem + 1.5em);
	box-sizing: border-box;
	border: 0;
	outline: 0;
	color: inherit;
	background: 0 0;
	font: inherit;
	flex: 1;
	display: flex;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
}

.k-dropdown .k-dropdown-wrap.k-state-focused {
	border-color: #bfc6d0;
	color: #212529;
	background-color: #c7cdd5;
	box-shadow: 0 0 0 3px rgba(228,231,235,.5);
}

.k-filtercell-operator > .k-dropdown > .k-dropdown-wrap > span.k-input {
	display: none;
}

.k-list .k-item:hover.k-state-selected, .k-list-optionlabel:hover.k-state-selected, .k-list__item:hover.k-state-selected,
.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected,
.k-list__item.k-state-selected, .k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
	color: #fff;
	background-color: $sigef-verde-accent;
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
	color: #fff;
	background-color: $sigef-primary;
}

.k-grid th.k-selected, .k-grid td.k-selected, .k-grid tr.k-selected > td {
	background-color: rgb(0 89 47 / 12%) !important;
}

.k-checkbox:checked, .k-checkbox.k-checked, .k-checkbox:indeterminate, .k-checkbox.k-indeterminate {
	border-color: $sigef-verde-accent !important;
	color: white;
	background-color: $sigef-primary !important;
}

.k-checkbox:focus, .k-checkbox.k-focus {
	border-color: #86b7fe !important;
	outline: 0.25rem solid rgba(13, 110, 253, 0.25) !important;
}

.k-switch-container {
	border-radius: 5em;
	padding: 3px;
	width: 5em;
	border-width: 0;
	border-style: solid;
	flex: 1 0 auto;
	position: relative;
	outline: 0;
	transition: background-color .2s ease-out 0s;
}

.k-switch {
	cursor: pointer;
	font-family: Poppins,Helvetica,sans-serif;
	font-size: .75rem;
	line-height: 1.5;
	text-align: left;
}

.k-switch-on .k-switch-container, .k-switch-on .k-switch-track {
	color: #fff;
	background-color: $sigef-primary;
	border-color: $sigef-verde-accent;
}

.k-switch-on.k-state-hover .k-switch-container, .k-switch-on:hover .k-switch-container, .k-switch-on:hover .k-switch-track {
	color: #fff;
	background-color: #004223;
}

.k-switch-md .k-switch-thumb {
	width: 20px !important;
	height: 20px !important;
}

.k-switch-md .k-switch-track, .k-switch-md {
	width: 45px;
	height: 25px;
}

.k-switch-md.k-switch-on .k-switch-thumb-wrap {
	left: 33px;
}

.k-switch-track {
	height: 1.85rem !important;
}

.k-switch-off .k-switch-track {
	border-color: #e4e7eb !important;
	background-color: #e4e7eb !important;
}

.k-switch-on:focus .k-switch-track, .k-switch-on.k-focus .k-switch-track {
	border-color: #86b7fe !important;
	outline: 0.25rem solid rgba(13, 110, 253, 0.25) !important;
}

.k-switch-off .k-switch-thumb {
	background-color: #ffffff !important;
}

.k-colorpicker .k-picker-wrap, .k-colorpicker .k-input-inner {
	transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
	border-color: #e4e7eb;
	color: #212529;
	background-color: #e4e7eb;
}

.k-input-md.k-icon-picker .k-input-inner, .k-picker-md.k-icon-picker .k-input-inner {
	width: 2.4rem;
	height: 2.4rem;
	padding: 0 !important;
	box-sizing: content-box;
} 

.k-colorpicker .k-picker-wrap {
	border-radius: .25rem;
	padding: 0;
	width: 100%;
	border-width: 1px;
	border-style: solid;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: row;
	transition: all .1s ease;
	overflow: hidden;
	cursor: default;
	outline: 0;
}

.k-colorpicker {
	width: auto;
	border-width: 0;
	box-sizing: border-box;
	outline: 0;
	font-family: Poppins,Helvetica,sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	background: 0 0;
	text-align: left;
	display: inline-flex;
	vertical-align: middle;
	position: relative;
	overflow: visible;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.k-colorpicker .k-selected-color, .k-colorpicker .k-color-preview {
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
	height: 100%;
	border-width: 0 1px 0 0;
	border-style: solid;
	border-color: inherit;
	box-sizing: border-box;
	line-height: 0;
	position: relative;
	overflow: hidden;
	background-clip: unset;
	margin: 0;
}

.k-colorpicker > button {
	background: #e4e7eb !important;
}

.k-colorpicker .k-picker-wrap .k-select {
	border-width: 0 0 0 1px;
	border-color: inherit;
	transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
}

.k-colorpicker .k-picker-wrap .k-select {
	border-radius: 0 .25rem .25rem 0;
	padding: .375rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	text-align: center;
	cursor: pointer;
	border: 0 solid;
	border-top-color: currentcolor;
	border-top-width: 0px;
	border-right-color: currentcolor;
	border-right-width: 0px;
	border-bottom-color: currentcolor;
	border-bottom-width: 0px;
	border-left-color: currentcolor;
	border-left-width: 0px;
	border-color: inherit;
}

.k-colorpicker .k-selected-color .k-i-line {
	border-top: 1px solid #dc3545;
	width: 200%;
	height: 200%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-33%,-33%) rotateZ(45deg);
	transform-origin: 0 0;
}

.k-button {
	border-radius: .25rem;
	padding: .375rem .75rem;
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	font-size: 1rem;
	line-height: 1.5;
	font-family: inherit;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	outline: 0;
	-webkit-appearance: none;
	position: relative;
}

.k-icon {
	width: 1em;
	height: 1em;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	font-family: WebComponentsIcons;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 1;
	speak: none;
	text-transform: none;
	text-decoration: none;
	display: inline-block;
	vertical-align: middle;
} 

.k-switch-handle {
	border-radius: 5em;
	width: 2em;
	height: 2em;
	border-width: 0;
	border-style: solid;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	left: 0;
	transition: left .2s ease-out 0s;
}

.k-switch, .k-switch-container, .k-switch-handle {
	box-sizing: border-box;
}

.k-switch-label-off {
	text-align: right;
	right: 8px;
}

.k-switch-label-off, .k-switch-label-on {
	display: none;
	width: calc(100% + calc(-1 * (2em + calc(8px + 2px) + 3px)));
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	line-height: 2em;
	overflow: hidden;
	text-shadow: none;
}

.k-switch-label-on {
	text-align: left;
	left: 8px;
}

.k-switch-off .k-switch-handle {
	border-color: rgb(0, 89, 47);
	color: #424242;
	background-color: #00592f;
}

.k-switch-on .k-switch-handle {
	left: calc(100% - 20px);
}

.k-switch-off .k-switch-container {
	color: #424242;
	background-color: #e4e7eb;
}

.k-switch-label-off, .k-switch-label-on {
	display: none;
	width: calc(100% + calc(-1 * (2em + calc(8px + 2px) + 3px)));
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	line-height: 2em;
	overflow: hidden;
	text-shadow: none;
}

.k-dialog-titlebar {
	border-color: inherit;
	color: #fff;
	background-color: #fff !important;
}

.k-dialog-buttongroup {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem;
	padding: 1rem;
	border-width: 1px 0 0;
	border-style: solid;
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
	overflow: hidden;
	border-color: rgba(33,37,41,.125);
}

.k-text-primary, .k-color-primary {
	color: $sigef-verde-accent !important;
}

.k-i-line:before, .k-i-shape-line:before {
	content: "" !important;
}

.k-textbox {
	border: none !important;
}

.k-textbox::selection, .k-textbox .k-input::selection {
	color: white;
	background-color: $sigef-primary !important;
}

/* El input de kendo tiene un padding mayor al de metronic, con lo que se ve mal el k-state-invalid */
.k-widget.form-control .k-input {
	padding: 0.3rem 0rem;
	height: 2.2rem;
	border-radius: 3px;
}

.form-control { 
	padding: 0 0 0 5px !important;
}

.k-numerictextbox:focus-within, .k-input-solid:focus-within {
	box-shadow: 0 0 0 0.2rem rgb(1 31 17 / 50%) !important;
	border-color: #009245 !important;
}

.k-list-filter > .k-textbox {
	width: 100% !important;
	box-sizing: border-box;
	padding-left: .75rem;
	padding-right: calc(16px + 1rem);
}
.k-list-filter > .k-icon {
	position: absolute;
	right: calc(.75rem + 1rem);
	top: 50%;
	transform: translateY(-50%);
}

.k-numerictextbox .k-numeric-wrap {
	border-radius: .25rem;
	padding: 0;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	transition: all .1s ease;
	cursor: default;
	outline: 0;
	display: flex;
	flex-direction: row;
	color: #292b2c;
	background-color: #fff;
	border: none !important;
}

.k-state-disabled, .k-widget[disabled] {
	outline: 0;
	cursor: default;
	opacity: .65;
	-webkit-filter: grayscale(.1);
	filter: grayscale(.1);
	pointer-events: none;
	box-shadow: none;
}

.k-calendar .k-calendar-view table { 
	margin: 0;
	border-width: 0;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: separate;
	table-layout: fixed;
	outline: 0;
	position: relative;
	z-index: 1;
}


.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
	color: white;
	background-color: $sigef-primary !important;
}

.k-calendar .k-calendar-view .k-today,
.k-calendar .k-calendar-navigation li:hover, .k-calendar .k-calendar-navigation li.k-hover {
	color: $sigef-verde-accent;
}

.k-calendar .k-calendar-view .k-today .k-calendar-cell-inner, .k-calendar .k-calendar-view .k-today .k-link,
{
	box-shadow: inset 0 0 0 1px $sigef-primary;
}

.k-calendar .k-calendar-td.k-selected:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:focus .k-link, .k-calendar .k-calendar-td.k-selected.k-focus .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected.k-focus .k-link,
{
	border-color: rgba(0, 0, 0, 0.08);
	color: white;
	background-color: $sigef-verde-accent;
	box-shadow: inset 0 0 0 1px $sigef-primary;
}

.k-multiselect .k-multiselect-wrap.k-state-hover, .k-multiselect .k-multiselect-wrap:hover {
	border-color: rgba(33,37,41,.15);
	color: #292b2c;
	background-color: #fff;
}

.k-multiselect .k-multiselect-wrap {
	border-radius: .25rem;
	padding: 0 calc(.75rem + 16px) 0 0;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	transition: all .1s ease;
	cursor: text;
	outline: 0;
	transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
	color: #292b2c;
	background-color: #fff;
	border: 1px solid rgba(33,37,41,.15);
}

.k-multiselect .k-multiselect-wrap .k-searchbar {
	float: left;
	width: auto;
}

.k-multiselect .k-searchbar {
	flex: 1;
	display: flex;
	flex-direction: row;
}

.k-multiselect .k-multiselect-wrap .k-button:last-child {
	margin-bottom: .25rem;
	margin-bottom: calc(.25rem - 1px);
}

.k-multiselect .k-multiselect-wrap li.k-button {
	float: left;
}

.k-button.k-state-hover, .k-button:not(.btn-mixero):hover {
	background-color: #00421f !important;
}

.k-multiselect .k-multiselect-wrap .k-button {
	min-height: calc(1.5em + .25rem + 2px);
	padding: .125rem .375rem;
	margin: .25rem 0 0 .25rem;
	cursor: default;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	line-height: inherit;
	white-space: normal;
	margin: calc(.25rem - 1px) 0 0 calc(.25rem - 1px);
}

.k-button {
	transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
	border-color: #e4e7eb;
	color: #212529;
	background-color: #e4e7eb;
}

.k-multiselect .k-multiselect-wrap .k-clear-value {
	height: 1.5rem;
	line-height: 1.5rem;
	outline: 0;
	cursor: pointer;
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	opacity: .5;
	position: absolute;
	top: .375rem;
	right: 6.5px;
}

.k-multiselect .k-multiselect-wrap.k-state-focused .k-clear-value, .k-multiselect .k-multiselect-wrap.k-state-hover .k-clear-value, .k-multiselect .k-multiselect-wrap:hover .k-clear-value {
	display: inline-flex;
}

[role=button], [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
	cursor: pointer;
}

.k-time-header .k-time-now, .k-time-selector-header .k-time-now {
	color: $sigef-verde-accent;
}

.k-time-list .k-item:hover {
	color: $sigef-primary;
}

.k-filtercell .k-filtercell-operator > .k-button:not(.k-clear-button-visible) {
	visibility: hidden;
	pointer-events: none;
}

.k-filtercell .k-filtercell-operator > .k-button.k-clear-button-visible {
	visibility: visible;
}

.k-textarea, .k-textbox {
	transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
	border-color: rgba(33,37,41,.15);
	color: #292b2c;
	background-color: #fff;
}

textarea.form-control { 
	padding: 5px 0 5px 5px !important;
}

.k-grid td.k-state-selected, .k-grid th.k-state-selected, .k-grid tr.k-state-selected > td {
	color: #292b2c;
	background-color: rgba(0,104,55,.25);
}

.k-grid .k-command-cell, .k-grid .k-edit-cell, .k-grid .k-grid-edit-row td {
	padding: calc(.75rem - (calc(1.5em + .75rem + 2px) - 1.5em)/ 2) .75rem;
}

.k-grid td:first-child, .k-grid th:first-child {
	border-left-width: 0;
}

.k-grid .k-grid-content, .k-grid .k-grid-content-locked, .k-grid .k-grid-header-locked, .k-grid td {
	border-color: rgba(33,37,41,.125);
}

.k-grid-edit-row td {
	text-overflow: clip;
}

.k-grid td {
	border-width: 0 0 0 1px;
	vertical-align: middle;
}

.k-grid td, .k-grid th {
	padding: .75rem;
	border-style: solid;
	outline: 0;
	font-weight: inherit;
	text-align: inherit;
	overflow: hidden;
	text-overflow: ellipsis;
}

.k-font-weight-bold {
	font-weight: 700 !important;
}

.k-input-md .k-input-icon, .k-input-md .k-input-validation-icon, .k-input-md .k-input-loading-icon, .k-input-md .k-clear-value, .k-input-md .k-input-prefix > .k-icon, .k-input-md .k-input-suffix > .k-icon, .k-picker-md .k-input-icon, .k-picker-md .k-input-validation-icon, .k-picker-md .k-input-loading-icon, .k-picker-md .k-clear-value, .k-picker-md .k-input-prefix > .k-icon, .k-picker-md .k-input-suffix > .k-icon {
	padding: 0rem 0.1rem 0.1rem 0.1rem;
	margin-left: 0.2rem;
	width: 16px !important;
	height: 16px !important;
}

kendo-datepicker.k-datepicker.k-input, kendo-timepicker.k-timepicker.k-input {
	height: 31px;
	border-color: rgba(33,37,41,.15);
	color: #292b2c;
	background-color: #fff;
}

kendo-datepicker.k-datepicker.k-input {
	width: 12.4em !important;
	border: 1px solid rgba(33,37,41,.15);
	box-sizing: border-box;
	outline: 0;
	outline-color: initial;
	outline-style: initial;
	outline-width: 0px;
	font-family: Poppins,Helvetica,sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	text-align: left;
	white-space: nowrap;
	background: 0 0;
	display: inline-flex;
	vertical-align: middle;
	position: relative;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.k-combobox {
	width: 12.4em;
	border-width: 0;
	box-sizing: border-box;
	outline: 0;
	background: 0 0;
	font-family: Poppins,Helvetica,sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	text-align: left;
	white-space: nowrap;
	display: inline-flex;
	vertical-align: middle;
	position: relative;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.input-group {
	width: 97%;
}

.k-input-solid:focus, .k-input-solid.k-focus, .k-numerictextbox:focus-within, .k-input-solid:focus-within {
	box-shadow: 0 0 0 0.25rem rgb(0 146 69 / 18%);
}

.k-button-solid-base.k-selected, .k-button-solid.k-button-solid-primary, .k-button-solid.k-button.k-primary {
	border-color: $sigef-verde-accent;
	color: #ffffff;
	background-color: $sigef-primary;
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.k-chip { 
	border-radius: 5px !important;
	background-color: #e4e7eb;
}

.k-coloreditor {
	min-width: 1px !important;
}

kendo-numerictextbox.form-control.k-disabled {
	background: #f3f3f4 !important;
}

.k-searchbar {
	border: 1px solid rgba(33,37,41,.15) !important;
}

kendo-taglist kendo-searchbar.k-searchbar {
	border: none !important;
}

kendo-dateinput.k-input-solid.k-rounded-md.k-input-md.k-input.k-dateinput {
	border: none !important;
	box-shadow: none !important;
}
